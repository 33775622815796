<template src="./AutomaticCourseRegistration.html"></template>

<script>
import { mapGetters } from "vuex";
import locationService from "@/services/Locations";
import automaticRegistrationService from "@/services/AutomaticRegistration";

export default {
  name: "AutomaticCourseRegistration",
  data() {
    return {
      isComponentModalActive: false,
      errorMessage: false,
      jsonData: {
        devices: [],
        typeDisability: [],
        frequencyOfConsumption: "NA",
        courseId: "",
      },
      event: "",
      step: 1,
      countriesOptions: [],
      citiesOptions: [],
      getStates: [],
      getCities: [],
      communes: [],
      neighborhoods: [],
    };
  },
  mounted() {
    this.getCountries();
    this.getCommunes();
  },
  methods: {
    prev() {
      this.step--;
    },
    next() {
      this.step++;
    },
    async getCountries() {
      try {
        this.getCountries = await locationService.getCountries();
      } catch (e) {
        console.error("error", e);
      }
    },
    async getStatesChange(country) {
      try {
        this.getStates = await locationService.getStates(country);
      } catch (e) {
        console.error("error", e);
      }
    },
    async getCitiesChange(country, state) {
      try {
        this.getCities = await locationService.getCities(country, state);
      } catch (e) {
        console.error("error", e);
      }
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    async getCommunes() {
      try {
        this.communes = await locationService.getCommunes();
      } catch (e) {
        console.error("error", e);
      }
    },
    async getCommuneChange(comunne) {
      try {
        this.neighborhoods = await locationService.getNeighborhoods(comunne);
      } catch (e) {
        console.error("error", e);
      }
    },
    async saveInformation() {
      try {
        let courseId = this.$route.params.id;
        this.jsonData.courseId = courseId;
        await automaticRegistrationService.registerUser(
          JSON.stringify(this.jsonData)
        );
      } catch (e) {
        console.error("error", e);
      }
    },
  },
  computed: {
    ...mapGetters({
      user: "getUser",
    }),
  },
};
</script>

<style scoped lang="scss" src="./AutomaticCourseRegistration.scss"></style>